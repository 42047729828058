.buttonWhite-main{
    border-radius: 100px;
    color: #00204C;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 19.6px; /* 140% */ 
    padding: 20px 40px;
    border: none;
    border: 1px solid white;
    transition: ease-out 0.15s;
    background-color: white;
    cursor: pointer;
}

.buttonWhite-main:hover{
    background: none;
    color: white;
    transition: ease-in 0.15s ; 
}