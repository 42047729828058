.footer-home-main{
    margin-top: 100px;
    margin-bottom: 100px;
}
.footer-home-main-robo img{
    max-width: 320px;
    max-height: 300px;

    position: relative;
    top: -220px;
    left: 100px
}

.footer-home-main-logo img{
    max-width: 215px;
    max-height: 58px;
}

.footer-home-main-logo p{
    color: #00204C;
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.footer-home-main-mainFooter-row1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.footer-home-main-mainFooter-row1 div{
    
}
.footer-head1{
    color: #00204C;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.footer-head2{
    color: #00204C;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
}
.footer-appStore-image1{
    max-width: 120px;
    max-height: 40px;
    margin-right: 20px;
}
.footer-appStore-image2{
    max-width: 135px;
    max-height: 40px;
    margin-right: 20px;

}
.footer-copyright{
    color: #00204C;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
@media only screen and (max-width: 680px) {
    .footer-home-main{
        margin-left: -40px;
        margin-right: -40px;
    }
}
@media only screen and (max-width: 1190px) {
    .footer-home-main-robo img{
        top: auto;
        max-width: 100%;
    }
}
