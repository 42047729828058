.fundTransferMain{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
.fundTransferMain-main{
    /* background-color: #ced3c1da; */
    width: 60%;
    text-align: center;
    align-self: center;
    padding: 40px;
    border: 2px solid #F3F3F3;
    border-radius: 10px;
    /* min-height: 100vh; */
}



.fundTransferMain-e1{
    h2{
        font-family: 'Karla';
        color: #000;
        font-size: 32px;
    }
}

.fundTransferMain-e2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid black; */

    label{
        color: #93979A;
        font-family: 'Karla';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.5px; 
    }

    input{
        max-width: 90%;
        width: 90%;
        margin: 5px 0px 40px 0px;
        border-radius: 10px;
        background: #F5F5F5;
        padding: 22px 19px;
        border: none;
        font-size: 20px;

        &::placeholder{
            color: #BDBEC2;
            font-family: 'Karla';
            font-size: 20px;
            font-weight: 500;
        }

        &:focus{
            outline: none;
        }
    }
}

.fundTransferMain-e2-p1{
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    border-radius: 10px;
    margin-top: 5px;
    input{
        margin: 0;
    }
    span{
        /* background-color: aqua; */
        color: #1E2327;
        font-family: Karla;
        font-size: 20px;
        font-weight: 500;
        margin-right: 20px;
    }
    p{
        color: #EFC427;
        font-family: Karla;
        font-size: 20px;
        font-weight: 500;
        margin-right: 20px;
    }
}

.fundTransferMain-e2-p2{
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span{
        color: #93979A;
        font-family: 'Karla';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
    }
    p{
        color: #1E2327;
        font-family: 'Karla';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
    }

}
.fundTransferMain-e3{
    hr{
        background: #F3F3F3;
        height: 3px;
        border: none;
        margin-left: -40px;
        margin-right: -40px;
    }
    p{
        color: #93979A;
        font-family: 'Karla';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        text-align: left;
    }
}
.fundTransferMain-e4{
    bottom: 0;
    hr{
        background: #F3F3F3;
        height: 3px;
        border: none;
        margin-left: -40px;
        margin-right: -40px;
    }
}
.fundTransferMain-e4-p1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.fundTransferMain-e4-p1-1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span{
        color: #93979A;
        font-family: 'Karla';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
    }


    p{
        color: #1E2327;
        font-family: 'Karla';
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        margin: 0px 0px 10px 0px;
    }

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;

        span{
            color: #93979A;
            font-family: 'Karla';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            text-decoration-line: underline;
            margin-right: 5px;
        }

        p{
            color: #B6B6B6;
            font-family: 'Karla';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin: 0;
        }
    }

}
.fundTransferMain-e4-p1-2{
    /* background-color: aqua; */
    /* height: 100%; */
    button{
        color: white;
        font-family: 'Karla';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        border:  none;
        background-color: #FFCB13;
        border-radius: 15px;
        padding: 30px 45px;
    }
}

@media only screen and (max-width: 800px) {
    .fundTransferMain{
        margin-left: -40px;
        margin-right: -40px;
    }
    .fundTransferMain-main{
        width: 80%;
    }
    /* .fundTransferMain-e2{
        input{
            max-width: 598px;
        }
    } */
    .fundTransferMain-e2-p1{
        width: 100%;
    }
    .fundTransferMain-e2-p2{
        width: 100%;
    }
    .fundTransferMain-e4-p1-1{
        span{
            font-size: 15px;
            font-weight: 500;
        }
        p{
            font-size: 25px;
            font-weight: 500;
        }
        div{
            span{
                font-size: 15px;
                font-weight: 500;
            }
            p{
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
    .fundTransferMain-e4-p1-2{
        button{
            padding: 15px 20px;
            border-radius: 10px;
        }
    }
}

@media only screen and (max-width: 410px) {
    .fundTransferMain-e4-p1{
        flex-direction: column;
    }
    .fundTransferMain-e4-p1-1{
        flex-direction: row;
        justify-content: space-between;
        div{
            flex-direction: column;
        }
    }
    .fundTransferMain-e4-p1-2{
        width: 100%;
        button{
            width:100%;
        }
    }
}