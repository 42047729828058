.membership-main{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}


.membership-main-bg1{
    background-image: url('../../Assets/MembershipPlan/MembershipPlan_Background1.png');
    max-width: 1340px;
    max-height: 800px;
    width: auto;
    height: auto;
    padding: 140px 140px;
    margin: 60px 0px;
}
.membership-main-bg1-p1{
    color: #FFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}
.membership-main-bg1-p2{
    color: #FFF;
    text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.40);
    font-family: 'Poppins', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    max-width: 704px;
    margin: 20px 0px 20px 0px;
}
.membership-main-bg1-p3{
    color: #FFF;
    text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.40);
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    max-width: 625px;
    margin: 60px 0px 50px 0px;
}
.membership-main-bg2{
    background-image: url('../../Assets/MembershipPlan/MembershipPlan_Background2.png');
    max-width: 1060px;
    max-height: 1195px;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -160px;
    left: 140px;
    
}
.membership-main-bg2-p1{
    color: #00204C;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.membership-main-bg2-p2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 50px 0px;
}

@media only screen and (max-width: 1190px) {
    .membership-main-bg1{
        margin-left: -50px;
        margin-right: -50px;
        background-position: 50%;
        background-size: cover;
    }
    .membership-main-bg2{
        left: auto;
        margin-left: -50px;
        margin-right: -50px;
        position: static;
        max-height: fit-content;

        
    }
}
@media only screen and (max-width: 680px) {
    .membership-main-bg1{
        padding: 140px 20px;
    }
    .membership-main-bg1-p1{
        font-size: 16px;
    }
    .membership-main-bg1-p2{
        font-size: 30px;
    }
    .membership-main-bg1-p3{
        font-size: 18px;
    }
    .membership-main-bg2-p1{
        /* font-size: 25px; */
    }
}