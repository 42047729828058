@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.home-main{
    width: 100%;
}
.home-main-a1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.mineForFuture-image{
    max-width: 280px;
    max-height: 186px;
    width: auto;
    height: auto;
}

.plus-image{
    max-height: 150px;
    max-width: 150px;
    height: auto;
    width: auto;
    
}
.circle-image{
    max-height: 150px;
    max-width: 150px;
    height: auto;
    width: auto;
}
.home-main-c1{
    padding: 80px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.home-main-c1-head{
    color: #00204C;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: bolder;
    line-height: 98px; /* 140% */
}
.home-main-c1-head span{
    background-color: #A700FA;
    color: white;
    border-radius: 100px;
    padding: 0px 20px
}
.home-main-c1-subHead{
    max-width: 940px;
    color: #00204C;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-style: normal;
    line-height: normal;
    margin-top: 20px;
}
.card-style1{
    max-width: 280px;
    max-height: 130px;
}


.home-main-a2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    img{
        margin: 10px 5px;
    }
}

.home-main-a3{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.home-main-a3 img{
    max-width: 99vw;
}
.mineForFuture-image-small{
    display: none;
}


.circle-image-small, .plus-image-small{
    display: none;
}


@media only screen and (max-width: 1010px) {
    .home-main-c1-head{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
@media only screen and (max-width: 650px) {
    .home-main-c1-head{
        font-size: 38px;
        line-height: 38px;
        text-align: left;
        
        span{
            padding: 10px 0px;
            text-align: center;
        }
    }
    .home-main-c1-subHead{
        font-size: 18px;
    }
    .home-main-a1-big{
        display: none;
    }
    .mineForFuture-image-small{
        display: block;
    }
    .home-main-a2{
        /* flex-direction: column; */
        /* width: 100%; */
        /* background-color: aqua; */
        /* align-items: center; */

        img{
            /* margin: 10px 0px; */
        }
        
    }
    .home-main-a3{
        img{
            max-width: 97vw;
        }
    }

    .circle-image-small, .plus-image-small{
        display: block;
        width: 80px;
        position: inherit;
        left: 0;
        top:0
    }
}