@import url('https://fonts.googleapis.com/css2?family=Krub&display=swap');


.qrPage-main{
    /* background-color: aqua; */
    /* margin-left: -50px;
    margin-right: -50px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.qrPage-main-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.qrPage-main-card-qrCode{
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.qrPage-main-card-message{
    border-radius: 14px;
    background: #CECDFF;
    padding: 22px 12px;
    width: 70%;

    color: #000;
    font-family: 'Krub', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;

    span{
        margin-right: 10px;
    }
}

.qrPage-main-card-button{
    border-radius: 15px;
    background: #2078B4;
    width: 75%;
    padding: 22px 12px;
    
    color: #F2F2F2;
    text-align: center;
    font-family: 'Krub', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    border: none;
    cursor: pointer;
}
.qrPage-main-card-address{
    height: auto; 
    max-width: 100%; 
    width: 60%; 
    padding: 10px 40px; 
    background-color:rgba(194, 192, 255, 0.12);
    margin-top: 10px;
    border-radius: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    word-wrap: break-word;
    
    
}
@media only screen and (max-width: 768px){
    .qrPage-main-card-message{
        width: 100%;
    }
    .qrPage-main-card-button{
        width: 110%;
    }
    .qrPage-main-card-address{
        font-size: 13px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

    }
}