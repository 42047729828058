.connectWallet-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0px;
}
.connectWallet-main-c1{
    color: #00204C;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 500;
    line-height: 61.6px; /* 140% */
    max-width: 940px;
    vertical-align: middle;

    
}
.connectWallet-main-c2 button{
    border-radius: 100px;
    border: 1px solid #5175F3;
    background: #FFF;
    width: 215px;
    height: 60px;
    margin: 50px 0px 0px 0px;
    cursor: pointer;
}
@media only screen and (max-width: 680px) {
    .connectWallet-main-c1{
        font-size: 18px;
        line-height: normal;
        font-weight: bold;
    }
    .connectWallet-main-c1 img{
        height: 70px;
    }
}