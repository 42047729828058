@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');


.paymentOption-main{
    /* border-radius: 32px; */
    /* background: rgba(194, 192, 255, 0.12); */
    padding: 42px 42px 80px 42px;
    /* min-width: 715px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    /* margin-left: -50px;
    margin-right: -50px; */
}
.paymentOption-main-card{
    border-radius: 32px;
    background-color: rgba(194, 192, 255, 0.12);
    padding: 42px 42px 80px 42px;

}
.paymentOption-main-p1{
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 36px */
    margin-bottom: 100px;
}
.paymentOption-main-p2-paymentButton{
    border-radius: 15.341px;
    border: 1.534px solid rgba(0, 0, 0, 0.10);
    background: #DDE8F5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* min-width: 780px; */
    width: 800px;
    padding: 10px 25px;
    margin-bottom: 20px ;
    cursor: pointer;
}
.paymentOption-main-p2-paymentButton-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    h4{
        color: #000;
        font-family: 'Karla', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        margin: 0px 20px;
    }
}
@media only screen and (max-width:860px){
    .paymentOption-main{
        min-width: none;
        width: 100%;
        margin-left: -50px;
        margin-right: -50px;
    }
    .paymentOption-main-p2-paymentButton{
        min-width: auto;
        width: 280px;
    }


}
@media only screen and (min-width: 1000px){
    .paymentOption-main-p2-paymentButton{
        min-width: auto;
        width: 600px;
    }
}