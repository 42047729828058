@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Outfit&display=swap');


.card3-main{
    border-radius: 32px;
    background: rgba(194, 192, 255, 0.12);
    padding: 42px 42px 80px 42px;
    min-width: 715px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
}
.card3-main h2{
    color: #000;
    text-align: center;
    font-family: 'Outfit', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 36px */
    margin: 0px 0px 100px 0px;
}
.card3-main form{
    display: flex;
    flex-direction: column;
    max-width: 550px;
}
.card3-main{
    form{
        width: 100%;
    }
}


@media only screen and (max-width:715px){
    .card3-main{
        min-width: auto;
        /* width: 80%; */
    }
}