@import url('https://fonts.googleapis.com/css2?family=Karla:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap');

.dashboard-main{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dashboard-main-p1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: aqua; */
    width: 100%;
    flex-wrap: wrap;

}
.dashboard-main-p1-e1{
    width: 200px;
    height: 200px;
    /* background-color: brown; */
    border-radius: 200px;
    padding: 50px;
    /* border: 20px solid white; */
    background-image: url('../../Assets//Dashboard/Balance-Background.svg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4{
        color: #000;
        font-family: Inter;
        font-size: 25.16px;
        font-style: normal;
        font-weight: 500;
        /* line-height: normal; */
        letter-spacing: -0.377px;
        margin-bottom: 0;
    }
    p{
        color: #7B78AA;
        font-family: 'Poppins';
        font-size: 16.354px;
        font-style: normal;
        font-weight: 400;
        /* line-height: normal; */
        letter-spacing: -0.377px;
        margin-top: 0;
    }
}
.dashboard-main-p1-e2{
    width: 433px;
    height: 260px;
    font-family: 'Karla', sans-serif;
    
    background-size: cover;
    
    
    
    .cardDataDiv{
        font-size: small;
        text-decoration: solid;    
        text-transform: uppercase;    
        color: white;
        height: 92%;
        display: flex;
        flex-direction: column;
        .cardStatus{
            /* font-family: 'Bungee Spice', sans-serif; */
            h2{
                text-align: right;
                margin-right: 5px;
            }
            
        }

        .cardNumber{
            text-align: center;
            font-size: 15px;
            margin-top: 55px;
        }

        .cardHolderNameDiv{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 20px;
            margin-top: -30px;

            .cardHolderName{
                margin-left: 20px;
            }
            .cardExpiry{
                margin-right: 20px;
            }
        }

        .cardCvv{
            font-size: 25px;
            margin-top: -40px;
            margin-left: 20px;
            width: fit-content;
            h3:hover{
                cursor: pointer;
                span{
                    display: inline;
                }
            }

            span{
                display: none;

            }
        }

        
    }
}


.dashboard-main-p1-e3{
    max-width: 454px;
    width: 410px;
    max-height: 253px;
    flex-shrink: 0;
    border-radius: 40px;
    background: #DDE8F5;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12.5px);  
    padding: 29px 38px;
}
.dashboard-main-p1-e3-total{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard-main-p1-e3-total-left{
    h4{
        color: #8E8E8E;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    p{
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
.dashboard-main-p1-e3-total-right{
    h4{
        color: #8E8E8E;
        text-align: right;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    p{
        color: #47BBC4;
        text-align: right;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
.dashboard-main-p2{
    /* background-color: blue; */
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    

    .dashboard-main-p2-e{
        /* background-color: goldenrod; */
        width: 45%;
        min-width: 400px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);

        h2{
            font-weight: 100;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
            color: #47BBC4;
            margin-right: 50px;
            /* display: flex;
            
            flex-direction: row;
            justify-content: ;
            align-items: center;
            background-color: #47BBC4; */
        }
    }
    .dashboard-main-p2-e:hover{
        background-color: rgba(0, 0, 0, 0.101);
        cursor: pointer;
        -webkit-transition: background-color 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        -o-transition: background-color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
        transition: background-color 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    
}

@media only screen and (max-width: 920px) {
    .dashboard-main-p2{
        justify-content: center;
        .dashboard-main-p2-e{
            width: 80%;
            h2{
                margin-right: 10px;
            }
        }
    }
}

@media only screen and (max-width: 846px){
    .dashboard-main-p1{
        flex-direction: column;
    }
    .dashboard-main-p1-e2{
        margin: 20px 0px;
    }
    
}

@media only screen and (max-width: 580px){
    .dashboard-main-p1{
        /* background-color: #8E8E8E; */
        width: 96vw;

        .dashboard-main-p1-e2{
            width: 450px;
            height: 270px;
            /* aspect-ratio: 433 / 260; */


            .cardDataDiv{
                font-size: smaller;
                .cardNumber{
                    font-size: 16px;
                    margin-top: 55px;
                }
                .cardHolderNameDiv{
                    font-size: 21px;
                    margin-top: -30px;
                    .cardHolderName{
                        margin-left: 20px;
                    }
                    .cardExpiry{
                        margin-right: 20px;
                    }
                }
                .cardCvv{
                    font-size: 25px;
                    margin-top: -40px;
                    margin-left: 20px;
                }
            }
        }

        .dashboard-main-p1-e3{
            /* background-color: aqua; */
            padding: 10px;
            width: 90%;
            border-radius: 10px;
        }
    }
}
@media only screen and (max-width: 422px){
    

    .dashboard-main-p1{
        .dashboard-main-p1-e2{
            width: 395px;
            height: 237px;
            /* aspect-ratio: 433 / 260; */

            .cardDataDiv{
                font-size: smaller;
                .cardNumber{
                    font-size: 14px;
                    margin-top: 45px;
                }
                .cardHolderNameDiv{
                    font-size: 18px;
                    margin-top: -30px;
                    .cardHolderName{
                        margin-left: 20px;
                    }
                    .cardExpiry{
                        margin-right: 20px;
                    }
                }
                .cardCvv{
                    font-size: 22px;
                    margin-top: -30px;
                    margin-left: 20px;
                }
            }
            
        }
    }

    .dashboard-main-p2{
        .dashboard-main-p2-e{
            min-width: 350px;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 400px) {

    .dashboard-main-p1{
        .dashboard-main-p1-e2{
            width: 370px;
            height: 222px;
            /* aspect-ratio: 433 / 260; */


            .cardDataDiv{
                font-size: smaller;
                .cardNumber{
                    font-size: 13px;
                    margin-top: 35px;
                }
                .cardHolderNameDiv{
                    font-size: 17px;
                    margin-top: -30px;
                    .cardHolderName{
                        margin-left: 20px;
                    }
                    .cardExpiry{
                        margin-right: 20px;
                    }
                }
                .cardCvv{
                    font-size: 21px;
                    margin-top: -30px;
                    margin-left: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 375px){
    .dashboard-main-p1{
        width: 95vw;

        .dashboard-main-p1-e2{
            width: 335px;
            height: 201px;
            /* aspect-ratio: 433 / 260; */


            .cardDataDiv{
                font-size: smaller;
                .cardNumber{
                    font-size: 11px;
                    margin-top: 35px;
                }
                .cardHolderNameDiv{
                    font-size: 15px;
                    margin-top: -30px;
                    .cardHolderName{
                        margin-left: 20px;
                    }
                    .cardExpiry{
                        margin-right: 20px;
                    }
                }
                .cardCvv{
                    font-size: 19px;
                    margin-top: -30px;
                    margin-left: 20px;
                }
            }
        }
    }
    .dashboard-main-p2{
        .dashboard-main-p2-e{
            min-width: 335px;
        }
    }
}
