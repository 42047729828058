@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.header-main {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-left img {
  width: 215px;
  height: 58px;
  flex-shrink: 0;
  cursor: pointer;
}
.header-right {
  font-size: 14px;
  align-items: center;
  display: flex;
  width: 70%;
  flex-direction: row;
  justify-content: space-around;
}
.header-right a {
  text-decoration: none;
  color: #00204c;
  text-align: center;
  font-family: 'Open Sans', sans-serif;;
  font-size: 15px;
  font-style: normal;
  line-height: 60px; /* 428.571% */
  display: flex;
  align-items: center;
  font-weight: bold;
}
.navigation-small{
  display: none;
  
}

@media only screen and (max-width: 1190px) {
  .navigation-large{
    display: none;
  }
  .navigation-small{
    display: block;
  }
  .header-left{
    margin-left: -40px;
    margin-top: -14px;
  }
}


.menuToggle {
  display: block;
  position: relative;
  top: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  a {
    text-decoration: none;
    color: #232323;
    transition: all 0.3s ease;
    &:hover {
      color: tomato;
    }
  }
  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  span {
    position: relative;
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #00204c;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
}

.menuToggle {
  input {
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #ffffff;
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
      }
    }
    &:checked ~ .menuItem {
      transform: none;
    }
    
  }
}

.menuItem {
  overflow-y: scroll;
  max-height:85vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  position: absolute;
  width: 300px;
  height: 100vh;
  padding: 50px;
  padding-top: 125px;
  background: #00204c;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  li {
    padding: 10px 0;
    font-size: 22px;
  }

}

.menu--right {
  .menuToggle {
    position: fixed;
    right: 0;
    input {
      right: 50px;
    }
    span {
      right: 50px;
    }
  }
  
  .menuItem-sub1{
    padding-left: 40px;
    display: none;
  }

  .menuItem-sub1-controller:hover{
    .menuItem-sub1{
      display: block;
      ul{
        transition: opacity 1s ease;

      }
    }
  }



  .menuItem {
    right: 0;
    margin: -100px 0 0 0;
    transform: translate(100%, 0);

    
    li {
      
      a {
        color: white;
        font-family: 'Open Sans', sans-serif;;
        font-size: 26px;
        font-style: normal;
        &:hover {
          padding-left: 5px;
          color: purple;
        }
        
      }
    }
  }
}

