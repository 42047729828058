.card2-main{
    border-radius: 32px;
    background: rgba(194, 192, 255, 0.12);
    width: 450px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.card2-main-c1{
    color: rgba(53, 53, 53, 0.58);
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card2-main-c2{
    margin: 40px 0px;
}
.card2-main-c3{
    color: rgba(53, 53, 53, 0.93);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card2-main-c4{
    color: rgba(53, 53, 53, 0.58);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card2-main-c5{
    color: #BBB;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    margin: 40px 0px;
}
.card2-main-c5 span{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.card2-main-c6{
    width: 100%;
    /* background-color: #00204C; */
    text-align: center;
}
.card2-main-c6 button{
    color: #FFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.6px; /* 140% */
    border-radius: 100px;
    border: 1px solid #2078B4;
    background: #2078B4;
    box-shadow: -2.5px 4.33px 4px 2px rgba(0, 0, 0, 0.60);
    padding: 20px 40px;
    cursor: pointer;
}
@media only screen and (max-width: 768px) {
    .card2-main{
        max-width: 450px;
        width: auto;
    }
}