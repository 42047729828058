@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.fundTransfer-main{

}

.fundTransfer-main-p1{
    text-align: center;
    h2{

        color: #353535;
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-weight: bold !important;
    }
}
.fundTransfer-main-p2{
    h2{
        color: #818181;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.377px;
        margin: 5px 0px;
    }
    h4{
        color: #000;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -1px;
        margin: 5px 0px;
    }


    

}
.fundTransfer-main-p4{
    /* background-color: blue; */
    text-align: center;

    .fundTransfer-button{
        width: 310px;
        height: 48px;
        border-radius: 14px;
        border: none;

        color: #fff;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1px;
        cursor: pointer;
        margin: 10px 5px;
    }

    .fundTransfer-main-p4-e1{
        background-color: #F80F0F;
    }
    .fundTransfer-main-p4-e2{
        background-color: #53D39D;
    }

}
.fundTransfer-main-p5{
    h4{
        color: #818181;
        font-family: Poppins;
        font-size: 16.354px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.377px;
        margin-bottom: 0px;
    }
    hr{
        border: 1px solid #8D8D8D;
        background-color: #8D8D8D;
    }
    button{
        cursor: pointer;
        color: #818181;
        background-color: white;
        border: 1px solid #818181;
        border-radius: 6px;
        padding: 10px;
        font-size: 15px;
        &:hover{
            color: white;
            background-color: #818181;
        }
    }
}

