.transaction-main{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px auto;
    padding: 10px 0px;
    /* border: 0.5px solid grey; */
    border-radius: 3px;
}
.transaction-main-r1{
    display: flex;
    flex-direction: row;
    align-items: center;

    .transaction-main-r1-c2{

        margin-left: 20px;
        span{
            color: #818181;
            font-family: 'Poppins', sans-serif;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: -0.377px;
        }
        p{
            margin: 0;
            word-break: break-all;
            color: #818181;
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.377px;
        }
    }
}
.transaction-main-r2{

    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.377px;
}