.support-main{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    background-color: #E9F2FF;
    min-height: 100vh;
    form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
} 