.login-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-main-p2{
    background-color: rgba(194, 192, 255, 0.3);
    padding: 50px 0;
    width: 60%;
    border-radius: 32px;
    input{
        background-color: rgba(194, 192, 255, 0.3);
    }
}

.login-main-p2-e1{

    color: rgba(0, 0, 0, 0.60);
    text-align: center;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    text-decoration-line: underline;


    width: 80%;
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 60px 0px 10px 0px;

    span{
        cursor: pointer;
        &:hover{

            color: #A700FA;
        }
    }
}
.login-main-p3{
    width: 60%;
}
.login-main-p4{
    color: rgba(0, 0, 0, 0.60);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 145.833% */
    
    span{
        color: #A700FA;
        font-family: Outfit;
        font-style: normal;
        font-weight: 500;
        line-height: 35px;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
}
@media only screen and (max-width: 800px) {
    .login-main{
        form{
            width: 114%;
        }

        .login-main-p3{
            width: 114%;
        }
    }
    
}