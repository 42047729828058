.activateDebitCard-main{
    background-color: white;
    margin-left: -50px;
    margin-right: -50px;
    text-align: center;
}
.activateDebitCard-main h2{
    color: #353535;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.activateDebitCard-main-cards{
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.activateDebitCard-main-cards hr{
    width: 60%;
    margin: 40px;
}