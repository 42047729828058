.our-services-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
}


.our-services-main-a1 {
  margin: 0px 0px 0px 500px;
}
.our-services-main-a1 img {
  max-width: 165px;
  max-height: 200px;
}
.our-services-main-head {
  font-size: 44px;
}
.our-services-main-subHead {
  font-size: 20px;
}
.our-services-main-card-left {
  max-width: 1240px;
  max-height: 680px;
  width: auto;
  height: auto;
  margin: 50px 0;

}
.our-services-main-card-right {
  max-width: 1240px;
  max-height: 680px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-end;
  margin: 50px 0;

}
.our-services-card1 {
  background-image: url("../../../Assets/HomeAssets/OurServices-CardBackground1.png");
  background-size: cover;
  width: 90vw;
  height: fit-content;
  padding: 150px 55px;
}
.our-services-card2 {
  background-image: url("../../../Assets/HomeAssets/OurServices-CardBackground2.png");
  background-size: cover;
  width: 90vw;
  height: fit-content;
  padding: 150px 55px;
}
.our-services-card3 {
    background-image: url("../../../Assets/HomeAssets/OurServices-CardBackground3.png");
    background-size: cover;
    width: 90vw;
    height: fit-content;
    padding: 150px 55px;
  }

.our-services-main-card-main {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  gap: 0px;
  background-color: white;
  max-width: 650px;
  max-height: 481px;
  width: auto;
  height: auto;

  border-radius: 20px;
}



.services-card-div1 {
  /* background-color: #b7ed6a; */
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;

}

.services-card-div1 img {
  max-width: 320px;
  width: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.services-card-div2 {
  /* background-color: #98dfdb; */
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 1px solid black; */
  padding: 0px 20px;

}

.services-card-div2 p {
  color: #00204c;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  padding: 0px 40px;
  text-align: left;
  /* background-color: antiquewhite; */
}
.services-card-div3 {
  /* background-color: #58bdbd; */
  grid-row: 1 / 3;
  grid-column: 3 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}
.services-card-div3 img {
  max-width: 186px;
  max-height: 372px;
  width: auto;
  height: auto;
}




@media (max-width: 560px) {
  .our-services-main-card-main {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
  }

  .our-services-card1 {
    width: 80vw;
  }

  .services-card-div1{
    grid-area: 1 / 1 / 2 / 2;
  }
  .services-card-div2{
    grid-area: 2 / 1 / 3 / 2; 
  }
  .services-card-div3{
    grid-area: 3 / 1 / 4 / 2; 
  }

  .our-services-card1, .our-services-card2, .our-services-card3{
    padding: 150px 0px;
    width: 100vw;
  }
  .our-services-main-a1{
    img{
      display: none;
    }
  }
}

/* @media (max-width: 480px) {
  .our-services-main-card-main {
    grid-template: 1fr / 1fr;
    max-width: 100%;
    border-radius: 0; 
  }
} */