.claimNowButton-main{
    background-color: green;
    width: max-content;
    color: white;
    padding: 2px 24px;
    font-size: 15px;
    border-radius: 15px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}