.referralPage-main{
    /* position: relative; */
    background: url('../../Assets/ReferralPage/MainBg.jpeg') ;
    background-size: contain;
    /* position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; */
    min-height: 100vh;
    width: calc(100% + 90px);
    margin-left: -45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    color: white;
    text-align: center;
}
.referralPage-main-bg{
    z-index: 1;
}
.referralPage-main-bg,.image-box__overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.referralPage-main-content{

    h2{
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.40);
        font-family: Poppins;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 84px; /* 140% */
    }
   

}
.referralPage-main-content-container1{
    border-radius: 50px;
    background: rgba(22, 21, 27, 0.50);
    width: 100%;
    height: 364px;
    flex-shrink: 0;
    h4{
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px; /* 160% */
    }
    p{
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.40);
        font-family: Poppins;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 84px; /* 168% */

    }
    span{
        text-align: center;
        text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.20);
        font-family: Poppins;
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        line-height: 84px; /* 105% */
        background: linear-gradient(180deg, #F9E272 10.61%, #F8E271 10.61%, #BBA536 90.4%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.referralPage-main-content-container2{
    /* background-color: #BBA536; */
    align-items: start;
    justify-content: start;
    h2{
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        width: max-content;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
.referralPage-main-content-container2-link{
    border-radius: 10px;
    border: 3px solid #FFF;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 15px;
    
}