.joinUs-main{
    /* margin-top: 150px; */
    margin-bottom: 150px;
    background-image: url('../../../Assets/HomeAssets//JoinUs-Background.png');
    background-size: cover;
    margin-right: -50px ;
}
.joinUs-main-a{
    font-size: 24px;
    text-align: start;
}
.joinUs-main-b{
    font-size: 40px;
    /* background-color: beige; */
    width: 60%;
    text-align: start;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 80px;
    span{
        white-space: nowrap;
        width: fit-content;
    }
}
.joinUs-main-c{
    padding-top: 20px;
    button{
        border-radius: 100px;
        color: white;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 19.6px; /* 140% */ 
        padding: 20px 40px;
        border: none;
        border: 1px solid #00204C;
        transition: ease-out 0.15s;
        background-color: #00204C;
        cursor: pointer;

        &:hover{
            background: none;
            color: #00204C;
            transition: ease-in 0.15s ;
        }
    }
}
.joinUs-main-d{
    padding-top: 90px;
    img{
        width: 635px;
        height: 328px;
        border-radius: 15px;
    }
}
@media only screen and (max-width: 695px){
    .joinUs-main-b{
        font-size: 30px;
        width: 100%;
        /* background-color: antiquewhite; */
    }
    .joinUs-main-d{
        img{
            width: 80%;
            height: auto;
        }
    }
}
@media only screen and (max-width: 550px){
    .joinUs-main-b{
        font-size: 25px;
    }
    .joinUs-main-b{
        span{
            padding: 2px 10px;
        }
    }
}