.signup-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1{
        font-family: 'Poppins', sans-serif;

    }

    form{
        background-color: rgba(194, 192, 255, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        width: 60%;
        padding: 50px 0px;
        border-radius: 32px;

        input{
            background-color: rgba(194, 192, 255, 0.3);
        }

        button{
            width: 80%;
        }
    }
}
.signup-main-p2{
    width: 60%;
}
.signup-main-p3{
    
    color: rgba(0, 0, 0, 0.60);
    text-align: center;
    font-family: 'Outfit';
    font-size: 20px;
    font-style: normal;
    line-height: 35px; 
    align-self: center;
    
    span{
        color: #A700FA;
        font-family: Outfit;
        font-style: normal;
        line-height: 35px;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
    
}
@media only screen and (max-width: 800px) {
    .signup-main{
        form{
            width: 114%;
        }

        .signup-main-p2{
            width: 114%;
        }
    }
    
}