.card1-main{
    background-color: white;
    padding: 50px 38px;
    border-radius: 20px;
    max-height: 514px;
    max-width: 400px;
    margin: 20px 20px;
    min-width: 350px;
}
.card1-main-c1 img{
    /* width: 127px; */
    height: 114px;
    padding: 10px 0px;
}
.card1-main-c2{
    color: #00204C;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 0px;
}
.card1-main-c3{
    color: #00204C;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: bolder;
    line-height: normal;
    padding: 10px 0px;
    max-width: 315px;
}
.card1-main-c4{
    color: #00204C;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 160% */
    padding: 10px 0px 20px 0px;
    max-width: 315px;
}
.card1-main-c5{
    width: 100%;
    /* background-color: #00204C; */
    text-align: center;
}
.card1-main-c5 button{
    color: #FFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.6px; /* 140% */
    border-radius: 100px;
    border: 1px solid #2078B4;
    background: #2078B4;
    box-shadow: -2.5px 4.33px 4px 2px rgba(0, 0, 0, 0.60);
    padding: 20px 40px;
    cursor: pointer;
}
@media only screen and (max-width: 768px) {
    .card1-main{
        min-width: auto;
    }
}