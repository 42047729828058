.aboutGeoDBcoin-main{
    background-image: url("../../../Assets/HomeAssets/AboutGeoDBcoin-Background1.png");
    background-size: cover;
    max-height: 100vh;
    max-width: 100vw;
    background-attachment: fixed;
    
    padding: 186px 164px 400px 164px;
    /* position: absolute; */
    left: 0;
    right: 0;
    margin-left: -50px;
    margin-right: -50px;
}
.aboutGeoDBcoin-main-subHead{
    color: #FFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.aboutGeoDBcoin-main-head{
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 61.6px; /* 140% */
    max-width: 710.851px;
    max-height: 122.59px;
}
@media (max-width: 1041px) {

    .aboutGeoDBcoin-main{
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10vh 5vw 40vh;
    }
    .aboutGeoDBcoin-main-subHead{
        font-size: 3vw; 
    }
    .aboutGeoDBcoin-main-head{
        max-width: none;
        width: 100%;
        margin-bottom: 20px;
        font-size: 4vw; 
        max-width: 90vw; 
        margin-bottom: 5vh; 

    }
    .aboutGeoDBcoin-main-button{
        margin-top: 5vh;
    }
}
@media (max-width: 768px) {
    .aboutGeoDBcoin-main{
        height: 65vh;
    }
    .aboutGeoDBcoin-main-subHead {
      font-size: 5vw; 
    }
  
    .aboutGeoDBcoin-main-head {
      font-size: 6vw; 
      max-width: 90vw; 
    }
  }
  
  @media (max-width: 480px) {
    .aboutGeoDBcoin-main {
      padding: 10vh 5vw 10vh; 
    }
  
    .aboutGeoDBcoin-main-subHead {
      font-size: 7vw; 
    }
  
    .aboutGeoDBcoin-main-head {
      font-size: 8vw; 
      max-width: 90vw; 
      margin-bottom: 3vh; 
    }
  
    .aboutGeoDBcoin-main-button {
      margin-top: 3vh; 
    }
  }